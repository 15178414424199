.App {
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.form input {
  width: 30vw;
  height: 10vh;
  margin: 1vh;
  font-size: 3vh
}